import { Component } from '@/types/interfaces';
import React from 'react';

export interface GridProps extends Component {
  children: React.ReactNode;
}

/** @deprecated
 *  This is a legacy component, just use Tailwind instead: https://tailwindcss.com/docs/grid-template-columns
 */
const Grid: React.FC<GridProps> = ({ className, children, ...props }) => (
  <article
    className={`grid grid-cols-4 gap-x-5 m:grid-cols-8 l:grid-cols-12 ${className}`}
    {...props}
  >
    {children}
  </article>
);

export default Grid;
